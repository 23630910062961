<template>
  <nav class="nav-bar">
    <!-- <AppNavigationBarItem to="/" label="Home" icon="fluent:home-24-regular" /> -->
    <AppNavigationBarItem v-if="isLoggedIn" to="/customers" label="Customers" icon="fluent:people-24-regular" />
    <AppNavigationBarItem v-if="isLoggedIn" to="/chats" label="Chats" icon="fluent:chat-empty-24-regular" :badge="unreadMessages" />
    <AppNavigationBarItem v-if="hasRole('ADMIN')" to="/playground" label="Playground" icon="fluent:window-dev-tools-24-regular" />
    <AppNavigationBarItem v-if="isLoggedIn" class="nav-item" label="Log out" icon="fluent:sign-out-24-regular" @click.prevent="handleLogout" />
    <AppNavigationBarItem v-else to="/login" label="Login" />
  </nav>
</template>

<script setup lang="ts">
const { isLoggedIn, logout, hasRole } = useAuth()
const { unreadMessages } = useChatStore()
const Dialog = useDialog()

async function handleLogout() {
  if (await Dialog.confirm('Are you sure you want to log out?')) logout()
}
</script>
